var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"generateProject"},[_c('el-table',{staticStyle:{"width":"100%"},attrs:{"data":_vm.goodsList}},[_c('el-table-column',{attrs:{"label":"序号","type":"index"}}),_c('el-table-column',{attrs:{"prop":"subtitle","label":"项目名称","width":"400px"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('span',{staticStyle:{"border-bottom":"1px solid #156ED0","color":"#156ED0","padding-bottom":"2px"},on:{"click":function($event){return _vm.jump(scope.row)}}},[_vm._v(_vm._s(scope.row.subtitle))])]}}])}),_c('el-table-column',{attrs:{"prop":"addType","label":"添加方式"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('span',[_vm._v(_vm._s(scope.row.addType == 1 ? "匹配添加" : "手动添加"))])]}}])}),_c('el-table-column',{attrs:{"prop":"zcJibie","label":"项目层级"}}),_c('el-table-column',{attrs:{"prop":"startDate","label":"申报日期"}}),_c('el-table-column',{attrs:{"prop":"conditions","label":"申报条件","show-overflow-tooltip":true}}),_c('el-table-column',{attrs:{"prop":"formalSupport","label":"扶持方式"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return _vm._l((scope.row.formalSupport),function(item,index){return _c('div',{key:index,class:item == '资金扶持'
              ? 'money'
              : item == '称号认定'
              ? 'designation'
              : item == '股权投资'
              ? 'equity'
              : item == '减税降费'
              ? 'reduction'
              : 'financial',staticStyle:{"width":"60px","font-size":"12px","height":"20px","text-align":"center","line-height":"20px","margin-bottom":"10px"}},[_vm._v(" "+_vm._s(item)+" ")])})}}])}),_c('el-table-column',{attrs:{"prop":"subtitleDescribe","label":"扶持标准","show-overflow-tooltip":true},scopedSlots:_vm._u([{key:"default",fn:function(scope){return _vm._l((scope.row.subtitleDescribe.split('<br>')),function(item,index){return _c('span',{key:index},[_vm._v(_vm._s(item))])})}}])}),_c('el-table-column',{attrs:{"label":"操作"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-button',{attrs:{"size":"mini","type":"danger"},on:{"click":function($event){return _vm.handleDelete(scope.row)}}},[_vm._v("移除")])]}}])})],1),_c('div',{staticClass:"footer"},[_c('div',[_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.generate}},[_vm._v("生成建议表")])],1),_c('div',{staticStyle:{"width":"500px"}},[_vm._v(" ")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }