<template>
  <div class="dialog">
    <el-dialog title="匹配项目" :visible="dialogShow" @close="toggleDialog">
      <div v-show="isShow" class="top">
        <div>
          <el-input v-model="keyword" placeholder="请输入关键字"></el-input>
        </div>
        <div>
          <el-button type="primary" icon="el-icon-search" @click="search">搜索</el-button>
        </div>
      </div>
      <el-table :data="isShow?searchData:tableData" style="width: 100%"  @selection-change="handleSelectionChange">
        <el-table-column type="selection"> </el-table-column>
        <el-table-column
          prop="subtitle"
          label="项目名称"
          :show-overflow-tooltip="true"
        ></el-table-column>
        <el-table-column prop="addType" label="添加方式">
          <template v-slot="scope">
            <span>{{ scope.row.addType == 1 ? "自动" : "手动" }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="zcJibie" label="项目层级"> </el-table-column>
        <el-table-column prop="startDate" label="申报日期"> </el-table-column>
        <el-table-column
          prop="conditions"
          label="申报条件"
          :show-overflow-tooltip="true"
        ></el-table-column>
        <el-table-column prop="formalSupport" label="扶持方式">
          <template v-slot="scope">
            <div
              v-for="(item, index) in scope.row.formalSupport"
              :key="index"
              style="width:60px;font-size:12px;height:20px;text-align:center;line-height:20px;margin-bottom:10px"
              :class="item == '资金扶持'?'money':item == '称号认定'?'designation':item=='股权投资'?'equity':item == '减税降费'?'reduction':'financial'"
              >{{ item }}</div
            >
          </template>
        </el-table-column>
        <el-table-column prop="subtitleDescribe" label="扶持标准" :show-overflow-tooltip="true">
        </el-table-column>
      </el-table>
      <div style="margin-top: 20px">
        <el-button type="primary" @click="add">确定添加</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { request } from '../../../../../../network';
export default {
  name: 'dialogComponent',
  data () {
    return {
      tableData: [],
      searchData: [],
      keyword: '',
      multipleSelection: []
    };
  },
  computed: {
    isShow () {
      return this.$store.state.backstage.show_advice;
    },
    dialogShow () {
      return this.$store.state.backstage.dialogShow;
    }
  },
  mounted () {
    this.getAutoMatch('');
  },
  methods: {
    toggleDialog () {
      this.$store.commit('backstage/set_dialog_show', false);
    },
    getAutoMatch (keyWord) {
      request({
        method: 'GET',
        url: '/pcp/org/autoMatch',
        params: {
          customerId: localStorage.getItem('customer_id'),
          orgId: JSON.parse(localStorage.getItem('userinfo')).orgId,
          keyWord: keyWord
        }
      })
        .then((res) => {
          if (res.data.code === 200) {
            if (!keyWord) {
              this.tableData = res.data.data;
            } else {
              this.searchData = res.data.data;
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    handleSelectionChange (val) {
      this.multipleSelection = val;
    },
    // 添加项目到生成项目建议列表中
    add () {
      this.$store.commit('backstage/set_dialog_show', false);
      this.$emit('addTags', this.multipleSelection);
    },
    search () {
      if (this.keyword) {
        this.getAutoMatch(this.keyword);
      } else {
        this.$message.error('关键字不能为空');
      }
    }
  }
};
</script>

<style lang="less" scoped>
.top {
  display: flex;
  margin-bottom: 40px;
  > div:nth-child(1) {
    margin-right: 30px;
  }
}
.money{
    border: 1px solid #FF7200;
    color: #FF7200;
    width: 114px;
    height: 36px;
    border-radius: 2px;
}
.designation{
    border: 1px solid #1FAA53;
    color: #1FAA53;
    width: 114px;
    height: 36px;
    border-radius: 2px;
}
.equity{
    border: 1px solid #1F88F9;
    color: #1F88F9;
    width: 114px;
    height: 36px;
    border-radius: 2px;
}
.reduction{
    border: 1px solid #BF382F;
    color: #BF382F;
    width: 114px;
    height: 36px;
    border-radius: 2px;
}
.financial{
    border: 1px solid #6CC2ED;
    color: #6CC2ED;
    width: 114px;
    height: 36px;
    border-radius: 2px;
}
</style>
