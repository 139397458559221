<template>
  <div class="advice">
    <div>
      <el-tabs v-model="activeName">
        <el-tab-pane label="生成项目建议表" name="first">
          <generate-project :goodsList="goodsList"></generate-project>
        </el-tab-pane>
        <el-tab-pane label="项目建议表列表" name="second"
          ><project @check="handleSee"></project
        ></el-tab-pane>
      </el-tabs>
    </div>
    <div v-if="activeName == 'first'">
      <el-dropdown>
        <el-button type="primary">添加项目</el-button>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item @click.native="handleMatch">匹配添加</el-dropdown-item>
          <el-dropdown-item @click.native="handleManual">手动添加</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
    <dialog-component @addTags="getTags"></dialog-component>
  </div>
</template>

<script>
import GenerateProject from './components/GenerateProject.vue';
import project from './components/Project.vue';
import dialogComponent from './components/Dialog.vue';
export default {
  components: {
    GenerateProject,
    project,
    dialogComponent
  },
  data () {
    return {
      activeName: 'first',
      goodsList: []
    };
  },
  computed: {},
  methods: {
    handleMatch () {
      this.$store.commit('backstage/set_show_advice', false);
      this.$store.commit('backstage/set_dialog_show', true);
    },
    handleManual () {
      this.$store.commit('backstage/set_show_advice', true);
      this.$store.commit('backstage/set_dialog_show', true);
    },
    getTags (data) {
      this.goodsList = [ ...this.goodsList, ...data ];
      this.filterData();
    },
    handleSee (data) {
      this.activeName = data.toggle;
      this.goodsList = [ ...this.goodsList, ...data.goods ];
      this.filterData();
    },
    filterData () {
      for (const i in this.goodsList) {
        const isRepeat = this.goodsList.filter(item => {
          return item.id === this.goodsList[i].id;
        });
        isRepeat.length > 1 && this.goodsList.splice(i, 1);
      }
    }
  }
};
</script>

<style lang="less" scoped>
.advice {
  margin-top: 50px;
  background: #fff;
  padding: 20px;
  display: flex;
  > div:nth-child(1) {
    flex: 1;
  }
}
</style>
