<template>
  <div class="generateProject">
    <el-table :data="goodsList" style="width: 100%">
      <el-table-column label="序号" type="index"> </el-table-column>
      <el-table-column
        prop="subtitle"
        label="项目名称"
        width="400px"
      >
        <template slot-scope="scope"><span style="border-bottom:1px solid #156ED0;color:#156ED0;padding-bottom:2px" @click="jump(scope.row)">{{scope.row.subtitle}}</span></template>
      </el-table-column>
      <el-table-column prop="addType" label="添加方式">
        <template v-slot="scope">
          <span>{{ scope.row.addType == 1 ? "匹配添加" : "手动添加" }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="zcJibie" label="项目层级"> </el-table-column>
      <el-table-column prop="startDate" label="申报日期"> </el-table-column>
      <el-table-column
        prop="conditions"
        label="申报条件"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column prop="formalSupport" label="扶持方式">
        <template v-slot="scope">
          <div
            v-for="(item, index) in scope.row.formalSupport"
            :key="index"
            style="
              width: 60px;
              font-size: 12px;
              height: 20px;
              text-align: center;
              line-height: 20px;
              margin-bottom: 10px;
            "
            :class="
              item == '资金扶持'
                ? 'money'
                : item == '称号认定'
                ? 'designation'
                : item == '股权投资'
                ? 'equity'
                : item == '减税降费'
                ? 'reduction'
                : 'financial'
            "
          >
            {{ item }}
          </div>
        </template>
      </el-table-column>
      <el-table-column
        prop="subtitleDescribe"
        label="扶持标准"
        :show-overflow-tooltip="true"
      >
        <template slot-scope="scope">
            <span v-for="(item,index) in scope.row.subtitleDescribe.split('<br>')" :key="index">{{item}}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作">
        <template v-slot="scope">
          <el-button size="mini" type="danger" @click="handleDelete(scope.row)"
            >移除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <div class="footer">
      <div>
        <el-button type="primary" @click="generate">生成建议表</el-button>
      </div>
      <div style="width: 500px">&nbsp;</div>
      <!-- <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="10"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination> -->
    </div>
  </div>
</template>

<script>
import { request } from '../../../../../../network';
export default {
  name: 'GenerateProject',
  data () {
    return {
      currentPage: 1,
      total: 0
    };
  },
  props: {
    goodsList: {
      type: Array
    }
  },
  mounted () {

  },
  methods: {
    handleDelete (item) {
      this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.goodsList.forEach((v, i) => {
            if (v.id === item.id) {
              this.goodsList.splice(i, 1);
            }
          });
          this.$message({
            type: 'success',
            message: '删除成功!'
          });
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });
        });
    },
    generate () {
      const arr = [];
      this.goodsList.forEach((v) => {
        arr.push({
          formalId: v.id,
          addType: v.addType
        });
      });
      if (arr.length > 0) {
        request({
          method: 'POST',
          url: '/pcp/org/addProjectPush',
          data: {
            customerId: localStorage.getItem('customer_id'),
            orgId: JSON.parse(localStorage.getItem('userinfo')).orgId,
            memberId: JSON.parse(localStorage.getItem('userinfo')).id,
            results: JSON.stringify(arr),
            pushNo: ''
          }
        })
          .then((res) => {
            if (res.data.code === 200) {
              this.$message({
                message: res.data.data,
                type: 'success'
              });
            }
          })
          .catch((err) => {
            this.$message.error(err.data.data);
          });
      } else {
        this.$message({
          message: '项目建议表为空，请添加项目重新提交',
          type: 'warning'
        });
      }
    },
    jump (item) {
      this.$router.push({
        path: '/formaldetails',
        query: {
          id: item.id
        }
      });
    }

  }
};
</script>

<style lang="less" scoped>
.footer {
  margin-top: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.money {
  border: 1px solid #ff7200;
  color: #ff7200;
  width: 114px;
  height: 36px;
  border-radius: 2px;
}
.designation {
  border: 1px solid #1faa53;
  color: #1faa53;
  width: 114px;
  height: 36px;
  border-radius: 2px;
}
.equity {
  border: 1px solid #1f88f9;
  color: #1f88f9;
  width: 114px;
  height: 36px;
  border-radius: 2px;
}
.reduction {
  border: 1px solid #bf382f;
  color: #bf382f;
  width: 114px;
  height: 36px;
  border-radius: 2px;
}
.financial {
  border: 1px solid #6cc2ed;
  color: #6cc2ed;
  width: 114px;
  height: 36px;
  border-radius: 2px;
}
</style>
